.horizontal-sub-cta {
    background: $purpleToBlueGradient;
    padding: 1rem;
    border-radius: calc(8rem/16);
    position: relative;
    margin: 0 0 1rem 0;
    &.has-promo {
        padding: calc(36rem/16) 1rem 1rem 1rem;
    }

    &:before {
        content: '';
        position: absolute;
        top: calc(2rem/16);
        left: calc(2rem/16);
        width: calc(100% - (4rem/16));
        height: calc(100% - (4rem/16));
        background: $siteSecondaryDarkest;
        border-radius: calc(8rem/16);
    }

    h2,
    .horizontal-sub-cta__plan,
    .horizontal-sub-cta__plan-divider {
        position: relative;
        z-index: 1;
    }

    h2 {
        display: table;
        margin: 0 auto 1rem auto;
        font-size: calc(20rem/16);
        text-transform: uppercase;
        font-weight: bold;
        font-family: $tertiaryFont;
        background: $purpleToBlueGradient;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }

    .horizontal-sub-cta__plans-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .horizontal-sub-cta__plan-divider {
        color: $white;
        text-align: center;
        margin: 0 auto;
    }

    .horizontal-sub-cta__promo-title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: calc(4rem/16) calc(10rem/16) calc(4rem/16) calc(10rem/16);
        color: $black;
        text-align: center;
        text-transform: uppercase;
        background: $purpleToBlueGradient;
        border-top-left-radius: calc(8rem/16);
        border-top-right-radius: calc(8rem/16);
        line-height: 1.1;
        font-weight: 600;
        font-size: calc(14rem/16);
        //text-shadow: calc(2rem/16) calc(2rem/16) calc(2rem/16) rgba(0, 0, 0, 0.6);
        letter-spacing: calc(1rem/16);
    }

    .horizontal-sub-cta__plan {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: calc(18rem/16);
        line-height: 1.3;
        margin: 0 auto;
        text-align: center;
        .horizontal-sub-cta__cta {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-family: $tertiaryFont;
            background: #28213b;
            border-radius: calc(8rem/16);
            color: $white;
            padding: calc(8rem/16);
            transition: background .25s $sexyEase;
            margin: 0;
            &:hover,
            &:focus,
            &:active {
                background: $siteSecondaryDarker;
            }

            .horizontal-sub-cta__percent-discount {
                background: $blueToPurpleGradient;
                padding: calc(4rem/16) calc(8rem/16);
                border-radius: calc(4rem/16);
                text-shadow: calc(2rem/16) calc(2rem/16) calc(2rem/16) rgba(0, 0, 0, 0.6);
            }

            .horizontal-sub-cta__period {
                margin: 0 calc(12rem/16);
                font-size: calc(20rem/16);
            }

            .horizontal-sub-cta__prices {
                display: flex;
                flex-direction: column;
                text-align: center;
                font-size: calc(22rem/16);
                background: linear-gradient(90deg, #7ba4ff 0%, #e595e3 100%);
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
                line-height: 1.1;
                font-weight: bold;
            }
        }
    }

    @media screen and (min-width: $breakpointTablet) {
        .horizontal-sub-cta__plans-wrapper {
            flex-direction: row;
            justify-content: flex-end;
        }

        .horizontal-sub-cta__plan {
            .horizontal-sub-cta__cta {
                width: calc(229rem/16);
            }
        }

        .horizontal-sub-cta__plan-divider {
            margin: 0 calc(16rem/16);
        }
    }

    @media screen and (min-width: $breakpointWide) {
        display: flex;
        justify-content: center;
        align-items: center;
        h2 {
            margin: 0 calc(24rem/16) 0 0;
            padding: 0 0 0 calc(8rem/16);
        }

        .horizontal-sub-cta__plan {
            &.is-promo {
                width: auto;
            }

            &.is-normal-pricing {
                width: calc(50% - (8rem/16));
            }
        }
    }
}
